import { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { withTranslation } from 'react-i18next';
import Container from '../../common/Container';
import { SvgIcon } from '../../common/SvgIcon';
import { Button } from '../../common/Button';
import i18n from 'i18next';
import { ScrollHandler } from '../ScrollHandler';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LanguageSwitch,
  LanguageSwitchContainer,
  CustomLink,
  ContactWrapper,
} from './styles';

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <ScrollHandler />
        <CustomLink to='/#about' onClick={() => setVisibility(false)}>
          <Span>{t('About')}</Span>
        </CustomLink>

        <CustomLink to='/#products' onClick={() => setVisibility(false)}>
          <Span>{t('Product')}</Span>
        </CustomLink>

        <CustomLink to='/#gallery' onClick={() => setVisibility(false)}>
          <Span>{t('Gallery')}</Span>
        </CustomLink>

        <ContactWrapper
          style={{ width: '180px' }}
          to='/#contact'
          onClick={() => setVisibility(false)}
        >
          <Span>
            <Button>{t('Contact')}</Button>
          </Span>
        </ContactWrapper>

        {/* <Label htmlFor="select-lang">{t("Language")}</Label> */}
        <CustomNavLinkSmall>
          <LanguageSwitchContainer>
            <LanguageSwitch onClick={() => handleChange('pl')}>
              <SvgIcon
                src='pl.png'
                aria-label='homepage'
                width='30px'
                height='30px'
              />
            </LanguageSwitch>

            {/* <LanguageSwitch onClick={() => handleChange('lt')}> */}
            {/* <SvgIcon */}
            {/* src='lt.png' */}
            {/* aria-label='homepage' */}
            {/* width='30px' */}
            {/* height='30px' */}
            {/* /> */}
            {/* </LanguageSwitch> */}
            {/* <LanguageSwitch onClick={() => handleChange('ru')}> */}
            {/* <SvgIcon */}
            {/* src='ru.png' */}
            {/* aria-label='homepage' */}
            {/* width='30px' */}
            {/* height='30px' */}
            {/* /> */}
            {/* </LanguageSwitch> */}
            {/* <LanguageSwitch onClick={() => handleChange('en')}> */}
            {/* <SvgIcon */}
            {/* src='gb.png' */}
            {/* aria-label='homepage' */}
            {/* width='30px' */}
            {/* height='30px' */}
            {/* /> */}
            {/* </LanguageSwitch> */}
          </LanguageSwitchContainer>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify='space-between'>
          <LogoContainer to='/' aria-label='homepage'>
            <SvgIcon src='logo.png' width='200px' height='66px' />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
