import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollHandler = ({ children }: any) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      // let element = null;
      let element = document.getElementById('/');
      if (hash) {
        element = document.getElementById(hash.replace('#', ''));
      } else {
        element = null;
      }
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop : 0,
        });
      }, 100);
    };
    handleScroll();
  }, [pathname, hash]);

  return children;
};
