const routes = [
  {
    path: ['/privacy-policy'],
    exact: true,
    component: 'components/PrivacyPolicy',
  },
  {
    path: ['/products/hoist'],
    exact: true,
    component: 'components/Products/Hoist',
  },
  {
    path: ['/products/crane-kit'],
    exact: true,
    component: 'components/Products/CraneKit',
  },
  {
    path: ['/products/spare-parts'],
    exact: true,
    component: 'components/Products/SpareParts',
  },
  {
    path: ['/', '/home'],
    exact: true,
    component: 'pages/Home',
  },
];

export default routes;
