import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import Container from '../../common/Container';
import { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from './styles';

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);

  const HandleClose = () => {
    setOpen(false);
  };
  useEffect(() => {}, [open]);

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          {/* <Row justify='space-between'> */}
          {/* <Col lg={10} md={10} sm={12} xs={12}> */}
          {/* <Language>{t('Contact')}</Language> */}
          {/* <Large to='/'>{t('Tell us everything')}</Large> */}
          {/* <Para> */}
          {/* {t(`Do you have any question? Feel free to reach out.`)} */}
          {/* </Para> */}
          {/* <a href='mailto:surgentas@gmail.com'> */}
          {/* <Chat>{t(`Let's Chat`)}</Chat> */}
          {/* </a> */}
          {/* </Col> */}

          {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left='true' to='/'>
                {t('Support Center')}
              </Large>
              <Large left='true' to='/'>
                {t('Customer Support')}
              </Large>
            </Col> */}
          {/* </Row> */}
          <Row justify='space-between'>
            <Col lg={10} md={10} sm={12} xs={12}>
              {/* <Empty /> */}
              <Language>{t('Address')}</Language>

              {/* <Para>{t('office')}</Para>
              <Para>WORLDHOISTS (Tianjin) Co., Ltd</Para>
              <Para>Julian Li</Para>

              <a href='mailto:izihou.li@worldhoists.com'>
                zihou.li@worldhoists.com
              </a>

              <hr /> */}

              <Para>{t('Representative')}</Para>
              {/* <Para>UAB Surgentas</Para> */}
              <Para>Magdalena Schröder</Para>
              <a href='mailto:info@worldhoists.pl'>info@worldhoists.pl</a>
              <Para>
                <a href='tel:+48602327911'>+48 602 32 79 11</a>
              </Para>
              <Para>(polski, niemiecki, rosyjski)</Para>
              {/* <hr />

              <Para>{t('Partners')}</Para>
              <Para>PHU SPAW</Para>
              <Para>Salon:ul.Baranowicka 160</Para>
              <Para>mgr inż. Andrzej Rogowski</Para>
              <a href='mailto:andrzej.rogowski@spaw.pro'>
                andrzej.rogowski@spaw.pro
              </a>
              <Para>
                <a href='tel:+48602772030'>+48 602 77 20 30</a>
              </Para>
              <hr /> */}
            </Col>

            <Col lg={8} md={8} sm={12} xs={12}>
              {/* <Empty /> */}
              <Title>{t('Policy')}</Title>
              <Large to='/privacy-policy'>{t('Application Security')}</Large>
              {/* <Large to='/' left='true' onClick={ToPrivacyPolicy}>
                {/* onClick={() => setOpen(true)} */}
              {/* {t('Application Security')} */}
              {/* </Large> */}
              {/* <Policy open={open} close={HandleClose} /> */}
              {/* <Large left='true' to='/'>
                {t('Software Principles')}
              </Large> */}
            </Col>

            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t('Company')}</Title>
              <Large left='true' to='/'>
                {t('About')}
              </Large> */}
            {/* <Large left='true' to='/'>
                {t('Blog')}
              </Large>
              <Large left='true' to='/'>
                {t('Press')}
              </Large>
              <Large left='true' to='/'>
                {t('Careers & Culture')}
              </Large> */}
            {/* </Col> */}
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor='select-lang'>{t('Language')}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange('en')}>
                  <SvgIcon
                    src='united-states.svg'
                    aria-label='homepage'
                    width='30px'
                    height='30px'
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange('es')}>
                  <SvgIcon
                    src='spain.svg'
                    aria-label='homepage'
                    width='30px'
                    height='30px'
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          </Row>
        </Container>
      </FooterSection>
      {/* <Extra>
        <Container border={true}>
          <Row
            justify='space-between'
            align='middle'
            style={{ paddingTop: '3rem' }}
          >
            <NavLink to='/' aria-label='homepage'>
              <LogoContainer>
                <SvgIcon
                  src='logo.png'
                  aria-label='homepage'
                  width='300px'
                  height='100px'
                />
              </LogoContainer>
            </NavLink> */}
      {/* <FooterContainer>
              <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              />
              <a href="https://www.buymeacoffee.com/adrinlol">
                <img
                  src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=adrinlol&button_colour=FF5F5F&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
                  alt="Buy me a pizza"
                />
              </a>
            </FooterContainer> */}
      {/* </Row>
        </Container>
      </Extra> */}
    </>
  );
};

export default withTranslation()(Footer);
