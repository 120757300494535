import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import translationPl from './locales/pl/translation.json';
import translationLt from './locales/lt/translation.json';
import translationRu from './locales/ru/translation.json';
import translationEn from './locales/en/translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'pl',
    fallbackLng: 'pl',

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      pl: {
        translations: translationPl,
      },
      lt: {
        translations: translationLt,
      },
      ru: {
        translations: translationRu,
      },
      en: {
        translations: translationEn,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
